import React from "react"
import styled from "styled-components"
import Footer from "../sections/Footer"
import { Title1, Title2, Title3, Title4, Text } from "../assets/primitives"
import { Container, Row, Col } from "react-grid-system"
import Layout from "../components/layout"
import Strings from "../assets/strings"

const appFaq = () => (
  <Layout showCookieBanner={false}>
    <ContainerExt>
      <Row>
        <Col xs={12}>
          {Strings.appDriverFaq.sections.map(section => {
            return (
              <SectionContainer>
                <Title2>{section.headline}</Title2>
                {section.questions.map(questions => {
                  return (
                    <>
                      <Title4>{questions.question}</Title4>
                      <Text>{questions.answer}</Text>
                    </>
                  )
                })}
              </SectionContainer>
            )
          })}
        </Col>
      </Row>
    </ContainerExt>
  </Layout>
)

export default appFaq

const ContainerExt = styled(Container)`
  //padding: 3rem 0;
`
const SectionContainer = styled.div`
  margin-bottom: 4rem;
`
